import { BaseWidget } from './base-widget';

export class Anchor extends BaseWidget {
    static NAME = 'anchor';

    constructor(element) {
        super(element);
    }

    init() {
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            var href = $(this).attr('href');
            console.log(href);

            $('html, body').animate({
                scrollTop: $('' + href).offset().top - 200
            }, 500);
        });
    }
}
