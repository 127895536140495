import "core-js"

import { Anchor } from './widgets/anchor';
import { Dropdown } from './widgets/dropdown';
import { Faq } from './widgets/faq';
import { Footer } from './widgets/footer';
import { GravityForms } from './widgets/gravity-forms';
import { Header } from './widgets/header';
import { Image } from './widgets/image';
import { InView } from './widgets/in-view';
import { Menu } from './widgets/menu';
import { Lazyload } from './widgets/lazy-load';
import { Reviews } from './widgets/reviews';
import { ScrollTo } from './widgets/scroll-to';
import { Video } from './widgets/video';

// IE11 polyfill - If object-fit cover
// const pfObjectFitImages = require('object-fit-images');

// IE11 polyfill - If self hoasted video's
// const pfObjectFitVideos = require('object-fit-videos');

class App {
    constructor() {
        this.widgets = [];
        this.widgetMap = {
            [Anchor.NAME]: Anchor,
            [Dropdown.NAME]: Dropdown,
            [Faq.NAME]: Faq,
            [Footer.NAME]: Footer,
            [GravityForms.NAME]: GravityForms,
            [Header.NAME]: Header,
            [Image.NAME]: Image,
            [InView.NAME]: InView,
            [Menu.NAME]: Menu,
            [Lazyload.NAME]: Lazyload,
            [ScrollTo.NAME]: ScrollTo,
            [Video.NAME]: Video,
		};

        this.callOut();
        this.addEventListeners();
    }

    initWidgets($container) {
        $container = $container || $(document);

        for (const key in this.widgetMap) {
            const elements = $container[0].querySelectorAll('[data-'.concat(key).concat(']'));

            for (const element of elements) {
                this.attachWidgetToElement(element, key);
            }
        }

        if (location.hostname === 'localhost') {
            console.log(this.widgets);
        };
    }

    attachWidgetToElement(element, key) {
        for (const widget of this.widgets) {
            if (widget.element === element) {
                return;
            }
        }

        const newWidget = new this.widgetMap[key]( $(element) );
        newWidget.init();

        this.widgets.push(newWidget);
    }

    clearWidgets($container) {
        $container = $container || $(document);

        for (const key in this.widgetMap) {
            const elements = $container[0].querySelectorAll('[data-'.concat(key).concat(']'));

            for (const element of elements) {
                this.detachWidgetsFromElement(element);
            }
        }

        console.log('Current widgets: ', this.widgets);
    }

    detachWidgetsFromElement(element) {
        const filteredWidgets = [];

        for (const widget of this.widgets) {
            if (widget.element !== element) {
                filteredWidgets.push(widget);
            }
        }

        this.widgets = filteredWidgets;
    }

    addEventListeners() {
        this.initWidgets();
    }

    externalLinks() {
        // Add target _blank to external links
        $('a[href^="http"]:not([href*="' + location.host + '"])')
            .attr('target', '_blank')
            .attr('rel', 'noopener')
        ;
	}

    callOut() {
        console.log('%c Ben je op zoek naar een nieuwe website, webshop of online marketing? \nhttps://www.brthmrk.nl/\n', 'font-family:monospace;font-size:10px;color:#fc5219');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    require('intersection-observer');
    
    new App();
    
    $( document ).ready(function() {
        $('body').addClass('loaded');


        var lastScrollTop = 0;
        $(window).scroll(function(event){
            if ($(document).scrollTop() > 100) {
                var st = $(this).scrollTop();
                if (st > lastScrollTop){
                    // downscroll code
                    $('body').addClass('scroll-down');
                } else {
                    // upscroll code
                    $('body').removeClass('scroll-down');
                }
               lastScrollTop = st;
            }
        });

        if ('ontouchstart' in window || 'ontouch' in window ) {
            $('body').addClass('touch');
        } else {
            $('body').addClass('no-touch');
    	}

        $(".c-return-to-top__button").click(function() {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        });


        $(document).scroll(function() {
          var y = $(this).scrollTop();
          if (y > 800) {
            $('.c-return-to-top__button').addClass('is-active');
          } else {
            $('.c-return-to-top__button').removeClass('is-active');
          }
        });

       
    })
});