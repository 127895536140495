import { BaseWidget } from './base-widget';
const lazysizes = require('lazysizes');

export class Lazyload extends BaseWidget {
    static NAME = 'lazyload';

    constructor(element) {
        super(element);
    }

    init() {
        // if ('loading' in HTMLImageElement.prototype) {
        //     const images = document.querySelectorAll("img.lazyload");
        //     images.forEach(img => {
        //         img.src = img.dataset.src;
        //     });
        // } else {
        //     const lazysizes = require('lazysizes');
        // }
        lazysizes.init();
    }
}
