import { BaseWidget } from './base-widget';

export class Menu extends BaseWidget {
    static NAME = 'menu-overlay';

    constructor(element) {
        super(element);
        this.trigger = $('[data-menu-toggle]');
        this.closeButton = $('[data-menu-close]');
        this.content = $('[data-content]', this.element);
        this.closeOverlay = $('.c-site__overlay');
    }

    init() {
        this.trigger.on('click', (e) => {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        });

        this.closeOverlay.on('click', (e) => {
            if (this.isOpen) {
                this.close();
            }
        });

        this.closeButton.on('click', (e) => {
            if (this.isOpen) {
                this.close();
            }
        });

        $(window).on('resize', () => {
            if (this.isOpen) {
                this.close();
            }
        });
    }

    open() {
        this.isOpen = true;
        this.trigger.addClass('is-open');

        requestAnimationFrame(()  => {
            this.element.addClass('is-active');
            $('.site').addClass('is-fixed');
            $('.c-header').addClass('menu-open');
            $('.c-site__overlay').addClass('is-active');
           
            requestAnimationFrame(()  => {
                this.element.addClass('is-open');
                
                window.setTimeout(() => {
                    $('.c-menu-overlay__container', this.element).focus();
                }, 50);
            });
        });

        // $(window).on('scroll', () => {
        //     if (window.scrollY > 1000) {
        //         this.close();
        //     }
        // });
    }

    close() {
        this.isOpen = false;

        requestAnimationFrame(()  => {
            this.element.removeClass('is-open');

            requestAnimationFrame(()  => {
                this.trigger.removeClass('is-open');
                $('.site').removeClass('is-fixed');
                $('.site').removeClass('is-open');
                $('.c-site__overlay').removeClass('is-active');
                $('html').removeClass('menu-open');

                setTimeout((() => {
                    $('body').removeClass('menu-open');
                    this.element.removeClass('is-active');
                }), 300);
            });
        });
    }
}
