import { BaseWidget } from './base-widget';

export class Image extends BaseWidget {
    static NAME = 'image';

    constructor(element) {
        super(element);
        this.image = $('[data-image]', this.element);
    }

    init() {
        $('[title]').removeAttr('title');
    }
}
