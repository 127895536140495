import 'jquery-validation';
import autosize from 'autosize';
import { BaseWidget } from './base-widget';

export class GravityForms extends BaseWidget {
    static NAME = 'gform';

    constructor(element) {
        super(element);
    }

    init() {

        // Email only e-mail
        $( ".gform_row--email input" ).change(function() { 
            var emailadres = $('.gform_row--email input').val();
            var encodedEmail = encodeURI(emailadres);
            $('.gform_row--email input').val(encodedEmail);
        });

        // Fix for form submit.
        const formId = this.element.attr('id').split('_').slice(-1).pop();

        this.element.find('input').on('change',  event => {
            (window)['gf_submitting_' + formId] = false;
        });

        // prevent double click form submission
        this.element.submit(function () {
            if ($(this.element).valid()) {
                (window)['gf_submitting_' + formId] = false;
            } else {
                (window)['gf_submitting_' + formId] = true;
            }
        });

        $(this.element).on('change', '.gform_upload', function(){ 
            console.log('jemoedr');
            $(this).parent('.gform_upload-button').attr('data-text', $(this).val().replace(/.*(\/|\\)/, '') );
        }); 

        this.element.find('input:not(input[type=file]), textarea, select').wrap( "<div class='gform_input-wrapper'></div>" );

        // Don't show valid class on non-required fields
        this.element
            .find('.gform_row').not('.gfield_contains_required')
            .find('input, textarea, select')
            .on('change blur keyup click', function() {
                $(this).closest('li.gform_row').removeClass('is-valid');
            })
        ;

        if ($('.gform_row--textarea textarea')){
            autosize($('.gform_row--textarea textarea'));
        }

        // this.element.find('.gform_row--email').find('input').rules('add', { 
        //     email: true
        // });

        this.element
        .find('input, textarea, select')
        .focus(function(){
            $(this).closest('li.gform_row').addClass('is-focused');
        })
        .blur(function(){
            $(this).closest('li.gform_row').removeClass('is-focused');
       });

        // Validate form
        this.element.validate({
            onfocusout: function (element) {
                this.element(element);
            },
            unhighlight: function(element) {
                // $(element).closest('.gform_row').removeClass('is-valid');
            },
            highlight: function(element) {
                $(element).closest('.gform_row').addClass('is-error');
                $(element).closest('.gform_row').removeClass('is-valid');
            },
            success: function(element) {
                $(element).closest('.gform_row').addClass('is-valid');
                $(element).closest('.gform_row').removeClass('is-error');
                $(element).closest('.error').remove();
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.closest('.gform_row'));
            },
            debug: true,
            errorElement: 'span',
            errorClass: 'error',
        });

        const required = ['Dit veld is verplicht 😞', 'Dit veld is verplicht ☝️', 'Dit veld is verplicht 👀'];    
        let getMesage = required[Math.floor(Math.random() * required.length)]
        jQuery.extend(jQuery.validator.messages, {
            required: getMesage,
            remote: "Controleer dit veld.",
            email: "Vul hier een geldig e-mailadres in. 🙃",
            url: "Vul hier een geldige URL in.",
            date: "Vul hier een geldige datum in.",
            dateISO: "Vul hier een geldige datum in (ISO-formaat).",
            number: "Vul hier een geldig getal in.",
            digits: "Vul hier alleen getallen in.",
            creditcard: "Vul hier een geldig creditcardnummer in.",
            equalTo: "Vul hier dezelfde waarde in.",
            accept: "Vul hier een waarde in met een geldige extensie.",
            maxlength: jQuery.validator.format("Vul hier maximaal {0} tekens in."),
            minlength: jQuery.validator.format("Vul hier minimaal {0} tekens in."),
            rangelength: jQuery.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1} tekens."),
            range: jQuery.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1}."),
            max: jQuery.validator.format("Vul hier een waarde in kleiner dan of gelijk aan {0}."),
            min: jQuery.validator.format("Vul hier een waarde in groter dan of gelijk aan {0}.")
        });
    }
}
