import { throttle } from 'lodash';
import { BaseWidget } from './base-widget';

export class Reviews extends BaseWidget {
    static NAME = 'reviews';

    constructor(element) {
        super(element);            
        this.siteUrl = this.element.data('site');
        this.itemsArray = [];
    }

    init() {
        jQuery(document).ready(function ($) {
            // Review template
            (function() {

              var feedbackAPI = "https://www.feedbackcompany.com/api/v2/oauth2/token";
              var token = '';
              $.getJSON( feedbackAPI, {
                    client_id: "A0F021DFCB77C9519D9A8295751B5091",
                    client_secret: "71CABA4852F94122A2270EF8440B7186",
                    grant_type: "authorization_code"
                })
                .done(function( data ) {
                    token = data.access_token;
                    var feedbackAPI = "https://www.feedbackcompany.com/api/v2/review/";
                    
                    $.getJSON( feedbackAPI, {
                        access_token: data.access_token,
                    })
                    
                    .done(function( data ) {

                        var reviews = []
                    
                        var elements = $.each( data.reviews, function( i, item ) {
                            var review = []
                            
                            if (item.total_score > 4) {
                                review.push(item.client.gender, item.total_score, item.client.name);
                                

                                $.each( item.questions, function( i, question ) {
                                    if (question.question_id === "157596") {
                                        review.push(question.value);
                                    }
                                });

                                reviews.push(review);           

                                if ( i === 3 ) {
                                  return false;
                                }

                                localStorage.setItem('reviews', JSON.stringify(reviews));  

                            }
                        });

                        $.each( reviews, function( i, review ) {

                            if (review[1]) {

                                var stars = '.c-review-list__stars--' + i;
                                var starsHtml = '';
                                var s = 0;

                                for (s = 0; s < Math.trunc(review[1]); s++) {
                                     starsHtml = starsHtml + ' <svg width="14" height="14" viewBox="453 382 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M453 382h18v18h-18v-18zm0 0h18v18h-18v-18zm9.004 0l2.778 5.925 6.218.95-4.498 4.612 1.066 6.513-5.564-3.075-5.562 3.075 1.056-6.513-4.498-4.612 6.218-.95 2.786-5.925z" fill="#FFBD16" fill-rule="evenodd"/></svg>'
                                }

                                // if ((review[1] - Math.trunc(review[1])) == '.5')
                                var half = review[1] - Math.trunc(review[1]);
                                if (half == '0.5') {
                                    starsHtml = starsHtml + '<svg width="14px" height="14px" xmlns="http://www.w3.org/2000/svg"><g id="Social" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group-16"><g id="Group" fill="#FFBD16"><path d="M7 .007V11.61L2.677 14l.821-5.066L0 5.347l4.836-.739L7 .007z" id="Combined-Shape"/></g><path d="M14 .007V11.61L9.677 14l.821-5.066L7 5.347l4.836-.739L14 .007z" id="Combined-Shape" fill="#E1E1E1" transform="matrix(-1 0 0 1 21 0)"/></g></g></svg>'
                                }


                                $(stars).replaceWith(starsHtml);
                            }


                            if (review[2]) {

                                var client = '.c-review-list__title--' + i;

                                $(client).replaceWith(review[2]);

                            }


                            if (review[3]) {

                                var text = '.c-review-list__review--' + i;

                                $(text).replaceWith(review[3]);

                            }

                        });

                        $('.c-review-list__item').removeClass('blur');
                    });
                });
            })();

            // Reviews header + footer
            (function() {

              var feedbackAPI = "https://www.feedbackcompany.com/api/v2/oauth2/token";
              var token = '';
              $.getJSON( feedbackAPI, {
                    client_id: "A0F021DFCB77C9519D9A8295751B5091",
                    client_secret: "71CABA4852F94122A2270EF8440B7186",
                    grant_type: "authorization_code"
                })
                .done(function( data ) {
                    token = data.access_token;

                    var feedbackAPI = "https://www.feedbackcompany.com/api/v2/review/summary/";

                    $.getJSON( feedbackAPI, {
                        access_token: data.access_token,
                    })
                    
                    .done(function( data ) {

                        localStorage.setItem('reviewsTotal', JSON.stringify(data));  

                        // totalscore
                        var bestRating = 10;
                        var ratingCount = data.statistics[1].count;
                        var ratingValue = data.statistics[1].value * 2;

                        var ratingValueText = '.ratingValue';
                        var ratingCountText = '.ratingCount';
                        var bestRatingText = '.bestRating';
                        
                        $(ratingValueText).replaceWith(ratingValue);
                        $(ratingCountText).replaceWith(ratingCount);
                        $(bestRatingText).replaceWith(bestRating);

                    });
                });
            })();
        });
    }
}