import * as $ from 'jquery';
import { BaseWidget } from './base-widget';

export class Dropdown extends BaseWidget {
    static NAME = 'dropdown';

    constructor(element) {
        super(element);
    }

    init() {
        $('.c-menu-overlay .c-menu__item--dropdown > a').on('click', function(e) {
            if ($(this).parent().is( ".is-open" )) {
                
            } else {
              e.preventDefault();
              $(this).parent().addClass("is-open");
            }
        });

        $(".c-menu-overlay .c-menu__item--dropdown .c-menu__chevron").on('click', function(e) {
            $(this).parent().toggleClass("is-open");
        });
    }
}