import { BaseWidget } from './base-widget';

export class ScrollTo extends BaseWidget {
    static NAME = 'scroll-to';

    constructor(element) {
        super(element);
    }

    init() {
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 100
            }, 500);
        });

    }
}
