import { BaseWidget } from './base-widget';

export class Footer extends BaseWidget {
    static NAME = 'footer-dropdown';

   constructor(element) {
        super(element);
        
        this.footertitle = this.element.data('footer-dropdown');
        
        this.dropdownId = this.element.data('footer-dropdown');
        this.target = $(`[data-footer-target="${this.dropdownId}"]`);
    }

    init() {
        this.element.on('click', (e) => {
            e.preventDefault();

            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        });
    }

    open() {
        this.isOpen = true;
        $(this.footertitle).addClass('is-open');
        this.target.addClass('is-open');
    }

    close() {
        this.isOpen = false;
        $(this.footertitle).removeClass('is-open');
        this.target.removeClass('is-open');
    }
}
